import { parseSoapResponse } from 'api/GetLookupTables';
import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { FieldInputSelect } from './FieldInputSelect';

type Props = {
  repaidTypeId?: string;
};

const ItemStatusSelectComponent: FC<Props> = ({ repaidTypeId }) => {
  const [data, setData] = useState<string[] | undefined>([]);
  const { lookupTables } = useAppSelector((state) => state).app;

  useEffect(() => {
    const fetchData = async () => {
      const test123 = parseSoapResponse(lookupTables, 'ItemStatuses');

      const formattedData = test123?.filter((item) => item?.['b:RepairTypeId'] === repaidTypeId)?.map((item) => item?.['b:ItemStatusId']);

      const set = new Set(formattedData);

      const unique = Array.from(set);

      setData(unique);
    };

    fetchData();
  }, [repaidTypeId, lookupTables]);

  return <FieldInputSelect options={data?.map((item) => ({ label: item, value: item }))} fieldName="itemStatus" label="Item Status" />;
};

export const ItemStatusSelect = React.memo(ItemStatusSelectComponent);
