import { About } from 'pages/About';
import { CreateRepair } from 'pages/CreateRepair';
import { EditProfilePage } from 'pages/EditProfile';
import { Home } from 'pages/Home';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/create" element={<CreateRepair />} />
      <Route path="/edit/:id" element={<CreateRepair />} />
    </Routes>
  );
};
